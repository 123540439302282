<template>
    <teleport to="head">
        <title>{{ txt[prefs.lang].reset }} – PLANTA</title>
    </teleport>

    <h1>{{ txt[prefs.lang].reset }}</h1>

    <div class="centered narrow distanced" style="max-width: 700px;">

        <template v-if="token">
            <el-form>
                <el-form-item :label=txt[prefs.lang].new_password>
                    <el-input v-model="form.password" @keydown.enter="reset" :placeholder=txt[prefs.lang].new_password show-password></el-input>
                </el-form-item>
                <el-form-item :label=txt[prefs.lang].password_confirm>
                    <el-input v-model="form.password_confirm" @keydown.enter="reset" :placeholder=txt[prefs.lang].password_confirm show-password></el-input>
                </el-form-item>
                <p v-if="!me"><el-checkbox v-model="form.login_after" :label=txt[prefs.lang].login_after></el-checkbox></p>
            </el-form>

            <p v-if="isFilledWrong" class="red centered">{{ txt[prefs.lang][isFilledWrong] }}</p>
            <p v-if="resetError && !isFilledWrong" class="red centered">{{ txt[prefs.lang][resetError] }}</p>

            <div class="centered">
                <el-button type="success" @click="reset" :disabled="isFilledWrong" :loading="loading" round>{{ txt[prefs.lang].reset }}</el-button>
            </div>

        </template>

        <template v-else>
            <p v-if="tokenError">{{ txt[prefs.lang][tokenError] }}</p>
            <p v-else>CHECKING...</p>
            <p style="margin: 30px;">🌿</p>
        </template>
    </div>

</template>

<script>
import axios from "axios"
import { txt } from '@/use/txt'
import { computed, inject, reactive, ref } from "vue"
import router from '@/router/index'
import useCart from "@/use/cart"

export default {
    setup(props, context) {

        // console.log(router.currentRoute.value.query.email)
        const baseUrl = inject('baseUrl')
        const { prefs, message, me } = useCart()

        const form = reactive({ password: '', password_confirm: '', login_after: true })

        const resetError = ref(false)
        const token = ref(false)
        const tokenError = ref(false)
        const loading = ref(false)

        let err = { 403: "invalid_token", 404: "invalid_token", 422: "pls_check", 429: "too_many" }

        async function checkToken() {
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/check_token', {
                email: router.currentRoute.value.query.email,
                token: router.currentRoute.value.query.token
            }).then(r => {
                // console.log('R: '+r)
                token.value = true
            }).catch(e => {
                tokenError.value = err[e.response.status] ? err[e.response.status] : 'wrong'
                // message(txt[prefs.lang].error, txt[prefs.lang][tokenError.value], 'warning')
                token.value = false
                // console.log('E: '+e)
                axios.post('/api/error', { creds: form.email, type: 7, error: e.response.status })
            })
        }
        checkToken()

        async function reset() {
            if (loading.value || isFilledWrong.value) return
            loading.value = true
            resetError.value = false
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/reset', {
                email: router.currentRoute.value.query.email,
                token: router.currentRoute.value.query.token,
                password: form.password,
                login_after: form.login_after
            }).then(r => {
                // console.log('R: '+r)
                message(txt[prefs.lang].all_saved, txt[prefs.lang].has_reset, 'success')
                // context.emit('showLogin')
                token.value = false
                if (!me.value && form.login_after) context.emit('getMe')
                router.push({ path: '/profile' })
            }).catch(e => {
                resetError.value = err[e.response.status] ? err[e.response.status] : 'wrong'
                message(txt[prefs.lang].error, txt[prefs.lang][resetError.value], 'warning')
                // console.log('E: '+e)
                axios.post('/api/error', { creds: form.email, type: 8, error: e.response.status })
            })

            loading.value = false
        }

        const isFilledWrong = computed(() => {
            return form.password.length < 8 ? 'password_short' : form.password !== form.password_confirm ? 'password_failed' : false
        })

        return { prefs, me, txt, form, reset, resetError, token, checkToken, tokenError, loading, isFilledWrong }
    }
}
</script>
